<template>
  <div class="root-div mx-auto">
    <div class="py-20 mx-auto">
      <!--begin::Form-->
      <v-card class="login-div">
        <p class="h2 my-5 font-bold font-pink">Register your information</p>
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-form-group label="First Name">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="$v.form.firstName.$model"
              :state="validateState('firstName')"
              aria-describedby="feedback_first_name"
            />
            <b-form-invalid-feedback id="feedback_first_name">
              First Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Last Name">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="$v.form.lastName.$model"
              :state="validateState('lastName')"
              aria-describedby="feedback_last_name"
            />
            <b-form-invalid-feedback id="feedback_last_name">
              Last Name is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <span class="m-1">Address</span>
          <GmapAutocomplete 
            ref="autocompleteRef"
            class="mt-1 form-control form-control-solid p-2"
            placeholder=""
            @change="onChanged"
            @place_changed="onPlaceChanged"
            />
          <b-form-group class="mt-4" label="Phone Number">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              aria-describedby="feedback_phone"
            />
            <b-form-invalid-feedback id="feedback_phone">
              Phone Number is required and must be valid phone number.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <v-btn block large class="mt-5 text-capitalize" color="#EB4C60" @click="onSubmit">
            <span class="font-bold text-white font-15">Save</span>
          </v-btn>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->

        <!-- Sign up -->
        <div class="my-5 d-flex justify-content-center align-items-center">
          <span>Have an account?</span>
          <v-btn class="text-capitalize" @click="$router.push('/auth')" text color="#E54A5E">
            <u class="font-bold">Log in</u>
          </v-btn>
        </div>
      </v-card>
    </div>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { functions } from '../../main';
import { showLoading } from '../../functions';
import { httpsCallable } from 'firebase/functions';
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

export default {
  mixins: [validationMixin],
  name: "Register",
  components: {
    KTFooterExtended
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        phone: ''
      },
      email: localStorage.getItem('emailForRegister'),
      geoPoint: null,
      address: ''
    };
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      phone: {
        required,
        minLength: minLength(10)
      },
    }
  },
  mounted() {
    if (!this.email) {
      this.$toast.error('Unexpected error occurs!');
      this.$router.push('/auth');
    }
  },
  destroyed() {
    localStorage.setItem('emailForRegister', '');
  },
  methods: {
    onChanged(event) {
      this.$refs.autocompleteRef.$el.value = this.address;
    },
    onPlaceChanged(placeData) {
      if (placeData === null || placeData.geometry === null || placeData.geometry.location === null || placeData.formatted_address === null) {
        return;
      }
      const location = JSON.parse(JSON.stringify(placeData.geometry.location));
      this.geoPoint = { latitude: location.lat, longitude: location.lng };
      this.address = placeData.formatted_address;
      this.$refs.autocompleteRef.$el.value = this.address;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.address === '' || this.geoPoint === null) {
        this.$toast.error('Please enter your address.');
        return;
      }
      const firstName = this.$v.form.firstName.$model;
      const lastName = this.$v.form.lastName.$model;
      const phone = this.$v.form.phone.$model;

      const params = {
        email: this.email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        address: this.address,
        geoPoint: this.geoPoint,
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'registerUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        const userInfo = JSON.parse(response.data);
        if (userInfo) {
          this.$toast.success('Registered successfully.');
          this.$store.commit('set', ['myInfo', userInfo]);
          localStorage.setItem('myInfo', JSON.stringify(userInfo));
          if (this.$store.state.orderInfo === null) {
            this.$router.push('/');
          } else {
            this.$router.push('/booking');
          }
        } else {
          this.$toast.error('Unexpected error occurs!');
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>